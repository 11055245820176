.body{
    margin: 0;
    padding: 0;
  }
/* Home */
.container{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.title{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.buttonPanel button{
    width: 250px;
    height: 50px;
    border-radius: 6px;
    margin: 0 10px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

  /* Draggable */
  .custom-marker-cluster{
    background: #ffffff;
    border: 10px solid #f00800;
    border-radius: 50px;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }
  
  .markerList{
    border: 2px solid #000;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
  }
  
  .markerList:hover{
    background-color: rgb(218, 246, 255);
  }


/* Undraggable */
  .user{
    display: flex;
    padding-left: 10px;
    border: 1px solid black;
    border-radius: 3px;
    margin-bottom: 5px;
    cursor: pointer;
    padding: 5px;
  }
  .user:hover{
    background-color: rgb(192, 221, 247);
  }
  
  
  .dataList{
    width: 20%;
    max-height: 100vh;
    overflow-y: scroll;
  }
  
  .dataListItems{
    border: 2px solid black;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
  }
  
  .dataListItems:hover{
    background-color: rgb(238, 249, 255);
  }